import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "but-what-if"
    }}>{`But what if...`}</h1>
    <p>{`If you find out that your teenager is using alcohol or other drugs:`}</p>
    <ul>
      <li parentName="ul">{`Try to open up a conversation with them about why they are using drugs or alcohol.`}</li>
      <li parentName="ul">{`Keep calm.`}</li>
      <li parentName="ul">{`Allow them to tell their side of the story.`}</li>
    </ul>
    <p>{`If you are not sure how to deal with the situation, seek help from a trained mental health professional.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m7/21.svg" alt="Drinking and smoking" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      